<template>
  <div class="row">
    <div class="col-9">
      <span class="porcentaje-participacion-ciudadana">
        <h4>
          <small>
            {{ casilla.casilla_nombre }} <br />
          </small>
          <span class="font-weight-bold">
            {{ casilla.participacion_ciudadana_porcentaje }}%
          </span>
        </h4>
      </span>
    </div>
    <div class="col-3">
      <p class="font-weight-bold text-right mb-0">
        Total de la Lista Nominal
      </p>
      <h4 class="font-weight-bold text-right">
        {{ casilla.total_lista_nominal_numero }}
      </h4>
    </div>
    <div class="col-12">
      <b-progress
        :max="100"
        :value="casilla.participacion_ciudadana_porcentaje"
        style="height: 3.3rem;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EstadisticaCasilla',
  props: {
    casilla: Object,
  },
};
</script>
